<template>
  <div class="upload-c">
    <el-upload
      class="upload-demo"
      v-loading="uploadLoading"
      ref="upload"
      action=""
      accept="image/png, image/jpeg, image/tif, image/bmp"
      :limit="1"
      :file-list="fieList"
      :show-file-list="false"
      :on-change="fileChange"
      :on-remove="handleRemove"
      :auto-upload="false"
      drag>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">{{msg}}</div>
      <div class="el-upload__tip" slot="tip">{{decript}}</div>
    </el-upload>
  </div>
</template>

<script>
import oss from '@/utils/oss'
import { mapState } from 'vuex'
import _ from 'lodash'
const sleep = async (ms) => new Promise((resolve, reject) => setTimeout(() => resolve(), ms))
export default {
  name: 'Upload',
  data() {
    return {
      host: 'https://retinavessel-file.oss-ap-southeast-2.aliyuncs.com',
      uploadLoading: false,
      imageUrl: '',
      fileName: '',
      fieList: [],
      totalTime: 0
    };
  },
  computed: mapState({
    fundus: state => state.fundus.fundus
  }),
  props: {
    msg: String,
    decript: String,
    setTime: Function,
    time: Number,
    max_time: Number,
    clearTimer: Function
  },
  methods: {
    async fileChange (file) {
      console.log(file)
      this.uploadLoading = true
      try {
        this.sts = await oss.getSTS()
      } catch (err) {
        console.log(err)
        this.imageUrl = ''
      }
      this.uploadFile(file)
    },
    handleRemove () {
      this.imageUrl = ''
    },
    async uploadFile (file) {
      let reader = new FileReader();
      let rs = reader.readAsArrayBuffer(file.raw);
      console.log(file)
      let blob = null;
      reader.onload = async (e) => {
        if (typeof e.target.result === 'object') {
          blob = new Blob([e.target.result])
        } else {
          blob = e.target.result
        }
        await this.uploadblob(blob, file)
      }
    },
    async uploadblob (blob, file) {
      const id = new Date().getTime()
      try {
        const exName = file.name.split('.')[1]
        let path = `input/${id}.${exName}`
        const result = await oss.putBlob(this.sts.Credentials)(blob, path)
        console.log(JSON.stringify(result))
        this.imageUrl = result.url
        if (exName === 'tif' || exName === 'tiff') {
          this.imageUrl  = `${this.imageUrl}?x-oss-process=style/tif2png`
        }
        this.fileName = result.name
        this.setTime()
        this.$nextTick(() => {
          this.getResult()
        })
      } catch (err) {
        console.log(err)
        this.imageUrl = ''
        this.$notify({
          title: 'Upload failed, please try again',
          type: 'error'
        })
      }
    },
    async getResult () {
      if (this.time >= this.max_time) {
        this.uploadLoading = false
        this.fieList = []
        return
      }
      let fundus = _.cloneDeep(this.fundus)
      let nodesResult = null
      let edgesResult = null
      let name = this.fileName.split('.')[0]
      let rename = name.replace('input', 'output')
      const host = this.host
      let nodesUrl = `${host}/${rename}_measures.json`
      let edgesUrl = `${host}/${rename}_AVR.json`
      try {
        nodesResult = await axios.get(nodesUrl)
        edgesResult = await axios.get(edgesUrl)
      } catch (err) {
        await sleep(1000)
        // this.totalTime ++
        return this.getResult()
      }
      if (typeof nodesResult.data === 'object') {
        nodesResult.data = JSON.stringify(nodesResult.data)
      }
      if (typeof edgesResult.data === 'object') {
        edgesResult.data = JSON.stringify(edgesResult.data)
      }
      // finished
      fundus.nodesResult = {}
      fundus.nodesResult.tableData = JSON.parse(nodesResult.data.replace(/NaN/g, '"NaN"'))
      fundus.edgesResult = {}
      fundus.edgesResult.tableData = JSON.parse(edgesResult.data.replace(/NaN/g, '"NaN"'))
      fundus.imageUrl = this.imageUrl
      fundus.fileName = this.fileName
      fundus._seg = `${host}/${rename}_avoverl.png`
      fundus._av_crossing = `${host}/${rename}_crossing.png`
      fundus._avr = `${host}/${rename}_avr.png`
      fundus._artery = `${host}/${rename}_A.png`
      fundus._vein = `${host}/${rename}_V.png`
      // fundus.measurements_edges = `${host}/${rename}_measurements_edges.csv`
      // fundus.measurements_nodes = `${host}/${rename}_measurements_nodes.csv`
      fundus.reports_avr = `${host}/${rename}_AVR.csv`
      fundus.reports_measures = `${host}/${rename}_measures.csv`
      this.$store.commit('SET_FUNDUS', {
        fundus
      })
      this.clearTimer()
      this.uploadLoading = false
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #1F578E;
}
.upload-c  {
}
</style>
